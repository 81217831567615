
// Colors
$primary: #02075D;
$white: #FFF;
$platinum: #E5E5E5;
$silver: #AAAAAA;
$grey: #7F7B82;
$accent: #E3B505;
$black: #000000;

// Spacing
$xs: .25rem;
$six: .375rem;
$s: .5rem;
$m: .75rem;
$l: 1rem;
$xl: 1.5rem;
$xxl: 2rem;
$navHeight: 5rem;

// Transition

$basic: .15s ease-in-out;
