@import '../settings.scss';

.projectsContainer {
  margin-bottom: $navHeight;
}

.bestIcon {
  color: red;
}

.greenIcon {
  color: green;
}

.postrIcon {
  color: $primary;
}