@import '../settings.scss';

.footerContainer {
    background: $black;
    color: $white;
    padding: $xxl;
    text-align: center;
}

.icons8 {
    font-size: $m;
    margin-top: 0;
    margin-bottom: 0;
}

.icons8 > a {
    color: $white;
}