@import '../settings.scss';

.skillContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 6rem;
  width: 5rem;
}

.skillContainer > p {
  margin-bottom: $m;
}

.skillContainer > div > svg {
  transition-property: all;
  transition-duration: .5s;

}

.skillContainer > div > svg:hover {
  transform: rotateY(360deg);
} 

@media screen and (min-width: 576px) {
  .skillContainer {
    width: 6rem;
  }
}

@media screen and (min-width: 768px) {
  .skillContainer {
    width: 4.6rem;
  }
}

@media screen and (min-width: 992px) {
  .skillContainer {
    width: 5rem;
  }
}
